"use client"

import { useState } from 'react'
import './Navbar.css'
import {BiMenuAltRight} from 'react-icons/bi'
import {RxCross2} from 'react-icons/rx'
import {useMotionValueEvent, useScroll} from 'framer-motion';
// import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom'
import {HashLink as Link} from 'react-router-hash-link'
import { useAppSelector } from "../../hooks/useAppSelector";
import LogoutButton from '../LogoutButton/LogoutButton'

const Navbar = () => {
    const user = useAppSelector((state) => state.users.user);
  
    const isAdmin = user?.role === "Admin";
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false)
    const [navStyle, setNavStyle] = useState("");
    const { scrollYProgress } = useScroll();
    const loginEnabled = JSON.parse(process.env.REACT_APP_ENABLE_LOGIN || '');
    const adminEnabled = JSON.parse(process.env.REACT_APP_ENABLE_ADMIN || '');
    const isActiveStyle = {
        textDecoration: 'none',
        color: 'red'
      };
    useMotionValueEvent(scrollYProgress, "change", (latest) => {
        if (latest > 0.2) {
            setNavStyle("sticky");
        } else {
            setNavStyle("")
        }
    })

  return (
    <div className={`n-wrapper ${navStyle}`}>
        {/* desktop version */}
        <div className="container">
            <div className="n-container">
                {/* left side */}
                <div className="n-logo">
                <Link to="/#">
                <img src="/hero/logo.png" alt="Pragma Once Software" className="logo" />
                        </Link>
                </div>
                {/* right side */}
                <div className="n-right">
                    <div className="n-menu">
                        <Link  className="nav-link" to="/#what-we-do" smooth={true} >
                            <span>What we do</span>
                        </Link>
                        <Link  className="nav-link" to="/#how-it-works" smooth >
                            <span>How it works</span>
                        </Link>
                        <Link  className="nav-link" to="/#who-we-work-with"  smooth >
                            <span>Who we work with</span>
                        </Link>
                        <Link  className="nav-link" to="/#talent-hub" smooth>
                            <span className="highlight">Talent hub</span>
                          
                        </Link>
                        { (adminEnabled && isAdmin) &&  
                            <span className="nav-link"> 
                               <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to="/admin-dashboard">
                                    Admin
                                </NavLink> 
                            </span>
                        } 
                        { (loginEnabled) &&  
                        <span className="nav-link"><LogoutButton/></span>
                        }
                    </div>
                </div>
            </div>
        </div>

        {/* mobile version */}
        <div className="nm-container">
            {/* logo */}
            {/* <span>Pragma Once Software</span> */}
            <Link to="wwhome-wrapper" smooth={true} >
                <img src="/hero/logo.png" alt="" className="logo" />
                        </Link>
            {/* menu icon */}
            {
                !mobileMenuOpened ? 
                <BiMenuAltRight
                size={30}
                onClick={() => setMobileMenuOpened(true)} 
                /> : 
                <RxCross2 
                size={30}
                onClick={() => setMobileMenuOpened(false)}
                />
            }

            {/* mobile menu */}
            <div className="nm-menu"  style={ mobileMenuOpened ? {transform: "translateX(0%)"} : {}}>
                <Link  className="nav-link" to="/#what-we-do" onClick={() => setMobileMenuOpened(false)}>
                    <span className="mobile-menu">What we do</span>
                </Link>
                <Link  className="nav-link" to="/#how-it-works" smooth onClick={() => setMobileMenuOpened(false)}>
                    <span className="mobile-menu">How it works</span>
                </Link>
                <Link  className="nav-link" to="/#who-we-work-with" smooth onClick={() => setMobileMenuOpened(false)}>
                    <span className="mobile-menu">Who we work with</span>
                </Link>
                <Link  className="nav-link" to="/#talent-hub" smooth onClick={() => setMobileMenuOpened(false)}>
                    <span className="mobile-menu highlight">Talent hub</span>
                </Link>
                { (adminEnabled && isAdmin) &&  
                            <span className="nav-link"> 
                               <NavLink style={({ isActive }) => isActive ? isActiveStyle : {}} to="/admin-dashboard">
                                    Admin
                                </NavLink> 
                            </span>
                } 
                { (loginEnabled) &&  
                <span className="nav-link"><LogoutButton/></span>
                }
            </div>

        </div>
    </div>
  )
}

export default Navbar