import { CandidateState } from "@/types/TalentHub/Candidate";
import { QueryOptions } from "@/types/QueryOptions";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const URL = `${API_BASE_URL}/candidates`;

const initialState: CandidateState = {
    candidates: [],
    total: 0,
    loading: false,
    error: null,
    filteredCandidates: [],
  };

//Queries
const createQueryString = (options: QueryOptions) => {
    const params = new URLSearchParams();
    params.append("page", options.page.toString());
    params.append("pageSize", options.pageSize.toString());
    params.append("sortBy", options.sortBy);
    params.append("sortOrder", options.sortOrder);
    return params.toString();
  };
  

  //Define thunk for fetching all candidates
export const fetchAllCandidates = createAsyncThunk(
    "candidates/fetchAll",
    async (options: QueryOptions, { rejectWithValue }) => {
      try {
        const queryString = createQueryString(options);
        const response = await axios.get(`${URL}?${queryString}`);
        return response.data;
      } catch (error: any) {
        toast.error(
          error.response?.data?.message || "Failed to fetch candidates"
        );
        return rejectWithValue(error.response?.data);
      }
    }
  );
  
const candidateSlice = createSlice({
    name: "candidates",
    initialState,
    reducers: {
      sortCandidateByName: (state, action: PayloadAction<"asc" | "desc">) => {
        if (action.payload === "asc") {
          state.filteredCandidates.sort((a, b) => a.title.localeCompare(b.title));
        } else {
          state.filteredCandidates.sort((a, b) => b.title.localeCompare(a.title));
        }
      },
      searchCandidateByName: (state, action: PayloadAction<string>) => {
        const searchQuery = action.payload.toLowerCase();
        state.filteredCandidates = state.candidates.filter((candidate) =>
            candidate.title.toLowerCase().includes(searchQuery)
        );
      },
      clearCandidateSearch: (state) => {
        state.filteredCandidates = state.candidates;
      },
    },
    extraReducers: (builder) => {
      //Fetch all categories
      builder.addCase(fetchAllCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(fetchAllCandidates.fulfilled, (state, action) => {
        state.loading = false;
        state.candidates = action.payload.items;
        state.filteredCandidates = action.payload.items;
        state.total = action.payload.totalCount;
        state.error = null;
      });
      builder.addCase(fetchAllCandidates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
    },
  });
  
  const candidateReducer = candidateSlice.reducer;
  export const { sortCandidateByName, searchCandidateByName, clearCandidateSearch } =
  candidateSlice.actions;
  export default candidateReducer;
  