import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks/useAppSelector";
import { logout } from '../../redux/slices/usersSlice';
import { NavLink, useNavigate } from 'react-router-dom';
import {HashLink as Link} from 'react-router-hash-link'

const LogoutButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('user');
        navigate('/login');
    }

    const handleLogin = () => {
        navigate('/login');
    }
    
    const user = useAppSelector((state) => state.users.user);
    if(user){
        return <button onClick={handleLogout}>Logout {user.name}</button>
    }else{
        return <button onClick={handleLogin}>Login</button>
    }
}
export default LogoutButton;